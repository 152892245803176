export const navData = [
  {
    title: 'About Me',
    path: 'about'
  },
  {
    title: 'Experience',
    path: 'experience'
  },
  {
    title: 'Projects',
    path: 'projects'
  }
]