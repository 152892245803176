import React from 'react';

import Section from './../../components/Section';
import Column from './../../components/Column';
import SectionHeading from './../../components/SectionHeading';
import Card from './../../components/Card';
import FadeInUp from './../../components/FadeInUp';
import {
  InfoWrapper,
  InfoColumnWrapper,
  InfoDescriptionWrapper,
  InfoDescription,
  InfoImgWrapper,
  InfoImg,
} from './InfoElements';
import ProfileImg from './../../assets/CroppedProfileImg.jpg';

const InfoSection = () => {
  return (
    <>
      <Section id={'about'}>
        <InfoWrapper>
          <SectionHeading delay={100}>About Me</SectionHeading>
          <InfoColumnWrapper>
            <Column width='50%'>
              <InfoImgWrapper>
                <InfoImg alt='A portrait of me.' src={ProfileImg}/>
              </InfoImgWrapper>
            </Column>
            <Column>
              <Card>
                <InfoDescriptionWrapper>
                  <FadeInUp delay={100}>
                    <InfoDescription indent> I am a recent graduate of a coding bootcamp where I honed my skills in full stack development. With a drive to enter the tech industry, I am currently working on personal projects to showcase my skills and build my portfolio. I am passionate about using technology to solve complex problems and I am eager to bring my creativity and determination to a new role.</InfoDescription>
                  </FadeInUp>
                  <FadeInUp delay={200}>
                    <InfoDescription>Aside from my professional life, I am passionate about photography and skiing. I enjoy capturing special moments and beautiful landscapes through the lens of my camera, and I also love the thrill and challenge of skiing on the slopes. I am constantly seeking new opportunities to improve my skills in both photography and skiing and to explore new destinations.</InfoDescription>
                  </FadeInUp>
                </InfoDescriptionWrapper>
              </Card>
            </Column>
          </InfoColumnWrapper>
        </InfoWrapper>
      </Section>
    </>
  )
}

export default InfoSection;
