import React from 'react';

import ContactSection from '../sections/ContactSection';

const Contact = () => {
  return (
    <ContactSection />
  )
}

export default Contact;
