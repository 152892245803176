import React from 'react';

import Section from '../../components/Section';
import SectionHeading from '../../components/SectionHeading';
import Column from '../../components/Column';
import Card from '../../components/Card';
import FadeInUp from '../../components/FadeInUp';
import {
  ProjectsWrapper,
  ProjectsColumnWrapper,
  ProjectsTextWrapper,
  ProjectsHeading,
  ProjectsDescription,
  StyledLink,
} from './ProjectsElements';
import Accordion from '../../components/Accordion';
import LazyVideo from '../../components/LazyVideo';
import Fitlance from './../../assets/Fitlance.mp4'
import RYHair from './../../assets/Roland York Hair.mp4'


const ProjectsSection = () => {
  return (
    <Section id={'projects'}>
      <ProjectsWrapper>
        <SectionHeading delay={100}>Projects</SectionHeading>
        <ProjectsColumnWrapper>
          <Column>
              <ProjectsTextWrapper>
                <FadeInUp delay={200}>
                  <ProjectsHeading>
                    <StyledLink href='https://www.rolandyorkhair.com/' target='_blank'>
                      Roland York Hair
                    </StyledLink>
                  </ProjectsHeading>
                  <ProjectsDescription>Roland York Hair is a business site for a local seattle hairstylist.</ProjectsDescription>
                </FadeInUp>
              </ProjectsTextWrapper>
          </Column>
          <Column>
            <Card>
              <Accordion
                link='https://github.com/thomasfriedrichs/york-hair'
              >
                <LazyVideo video={RYHair} />
              </Accordion>
            </Card>
          </Column>
        </ProjectsColumnWrapper>
        <ProjectsColumnWrapper reverse>
          <Column>
              <FadeInUp delay={300}>
                <ProjectsTextWrapper>
                  <ProjectsHeading>
                    <StyledLink href='https://fitlance.azurewebsites.net/' target='_blank'>
                      Fitlance
                    </StyledLink>
                  </ProjectsHeading>
                  <ProjectsDescription>Fitlance is a social center for finding personal trainers and other fitness enthusiasts in your community.</ProjectsDescription>
                </ProjectsTextWrapper>
              </FadeInUp>
          </Column>
          <Column>
            <Card>
              <Accordion
                link='https://github.com/thomasfriedrichs/Fitlance'
              >
                <LazyVideo video={Fitlance} />
              </Accordion>
            </Card>
          </Column>
        </ProjectsColumnWrapper>
      </ProjectsWrapper>
    </Section>
  )
}

export default ProjectsSection;